const initialState = {
	animationPointsPositionOnPage: [],
};

const ADD_NEW_POINT_DATA = "ADD_NEW_POINT_DATA";
const CLEAR_POINT_DATA = "CLEAR_POINT_DATA";

export const addNewPointData = (data) => ({
	type: ADD_NEW_POINT_DATA,
	data,
});
export const clearPointData = () => ({
	type: CLEAR_POINT_DATA,
});

export default (state = initialState, action) => {
	switch (action.type) {
		case ADD_NEW_POINT_DATA:
			// newState = { ...state };
			let newState = { ...state };
			if (
				!newState.animationPointsPositionOnPage.filter(
					(item) => item === action.data.pos,
				).length
			) {
				newState.animationPointsPositionOnPage = [
					...state.animationPointsPositionOnPage,
				];
				newState.animationPointsPositionOnPage.push(action.data);
			}
			return newState;
		case CLEAR_POINT_DATA:
			state.animationPointsPositionOnPage = [];
			return { ...state };
		default:
			return state;
	}
};
